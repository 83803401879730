<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Adminpanel</h2>
        <v-row>
          <v-col cols="12" md="12">
            <h3 class="mt-3">Alle Checklisten</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" cols="12">


            <v-expansion-panels

            >
              <v-expansion-panel
                  v-for="vehicle in vehicles"
                  :key="vehicle._id"
              >
                <v-expansion-panel-header class="">{{ vehicle.name }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p class="text-subtitle-2">Checklisten in diesem Fhz:</p>
                  <v-expansion-panels>
                    <v-expansion-panel
                        v-for="list in vehicle.lists"
                        :key="list._id"
                    >
                      <v-expansion-panel-header>
                        <v-row no-gutters>
                          <v-col cols="1">
                            <v-icon color="">mdi-clipboard-list</v-icon>
                          </v-col>
                          <v-col cols="11">
                            {{ list.title }}
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row no-gutters>

                          <v-col cols="12">

                          </v-col>
                        </v-row>

                        <v-card-actions>
                          <v-btn
                              text
                              color="secondary"
                              @click="editChecklist(list)"
                          >
                            Bearbeiten
                          </v-btn>
                          <v-btn
                              text
                              color="red"
                              @click="confirmDelete(list)"
                          >
                            Löschen
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="showChecklist(list)"
                          >
                            Items Anzeigen
                          </v-btn>
                        </v-card-actions>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-divider class="mt-8 mb-0"></v-divider>
                  <v-card-actions class="">

                    <v-btn
                        text
                        color="secondary"
                        @click="editVehicle(vehicle)"
                    >
                      Name des Fhz ändern
                    </v-btn>
                    <v-btn
                        text
                        color="red"
                        @click="confirmVehicleDelete(vehicle)"
                    >
                      Fhz löschen
                    </v-btn>
                    <v-btn
                        text
                        color="success darken-1"
                        @click="openChecklistDialog(vehicle)">
                      Checkliste in diesem Fhz erstellen
                    </v-btn>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-divider class="my-6"></v-divider>
        <v-row>
          <v-col cols="12">
            <v-btn
                dark
                small
                @click="createVehicleDialog = true"
            >
              <v-icon left>mdi-plus</v-icon>
              Fahrzeug erstellen
            </v-btn>

          </v-col>

        </v-row>
      </v-col>
    </v-row>

    <v-divider class="my-6"></v-divider>


    <v-row>
      <v-col>
        <h3>Benutzer</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Benutzername
              </th>
              <th class="text-left">
                Rolle
              </th>
              <th class="text-left">
                Aktionen
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="user in userList"
                :key="user.username"
            >
              <td>{{ user.username }}</td>
              <td>{{ user.group }}</td>
              <td>
                <v-btn icon elevation="0">
                  <v-icon @click="editUser(user)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="ml-3" icon elevation="0" color="red">
                  <v-icon @click="confirmUserDelete(user)">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-divider class="my-6"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-btn
            dark
            small
            @click="dialog.createUser = true"
        >
          <v-icon left>mdi-plus</v-icon> Benutzer erstellen
        </v-btn>

      </v-col>

    </v-row>

    <v-divider class="my-6"></v-divider>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>Infonachricht bearbeiten</h3>
            <p>Die Nachricht wird auf der Startseite (Fahrzeugauswahl) als Infobanner angezeigt.</p>

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="10">
            <v-text-field
                v-model="messageText"
                label="Infotext">

            </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn fab @click="saveCustomMessage()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="my-6"></v-divider>
    <!-- Document uploads -->
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>Dateien hochladen</h3>
            <p>Die Dateien können von allen Benutzern auf der Startseite (Fahrzeugliste) heruntergeladen werden.</p>
            <v-btn
                dark
                small
                @click="dialog.fileUpload = true"
            >
              <v-icon left>mdi-upload</v-icon> Datei hochladen
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="my-6"></v-divider>
        <v-row>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Titel
                  </th>
                  <th class="text-left">
                    Dateiname
                  </th>
                  <th class="text-left">
                    Aktionen
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="file in fileList"
                    :key="file.title"
                >
                  <td>{{ file.title }}</td>
                  <td>{{ file.path }}</td>
                  <td>
                    <v-btn class="ml-3" icon elevation="0" color="red">
                      <v-icon @click="confirmFileDelete(file)">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--    DIALOG  -->

    <v-dialog
        v-model="dialog.fileUpload"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Neue Datei</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    v-model="fileTitle"
                    label="Titel"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input v-model="uploadedFile" label="Datei auswählen"></v-file-input>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog.fileUpload = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="uploadFile"
          >
            Hochladen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="createVehicleDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Neues Fahrzeug</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-text-field
                    v-model="newVehicle"
                    label="Fahrzeugname"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="createVehicleDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="addVehicle"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.editVehicle"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Fahrzeug bearbeiten</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-text-field
                    v-model="editVehicleName"
                    label="Fahrzeugname"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog.editVehicle = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveVehicle()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.createUser"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Neuer Benutzer</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
              >
                <v-text-field
                    v-model="newUser"
                    label="Benutzername"
                    required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
              >
                <v-select
                    v-model="newUserGroup"
                    label="Gruppe"
                    required
                    :items="userGroups"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
              >
                <v-text-field
                    v-model="newUserPassword"
                    label="Passwort"
                    required
                ></v-text-field>
              </v-col>
            </v-row>

          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog.createUser = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="createUser"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.editUser"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Benutzer bearbeiten</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
              >
                <v-text-field
                    v-model="editedUser.username"
                    label="Benutzername"
                    required
                    v-if="editedUser"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
              >
                <v-select
                    v-model="editedUser.group"
                    label="Gruppe"
                    required
                    :items="userGroups"
                    v-if="editedUser"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
              >
                <v-text-field
                    v-model="editedUser.password"
                    label="Passwort"
                    v-if="editedUser"
                ></v-text-field>
              </v-col>
            </v-row>

          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog.editUser = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveUser"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.editChecklist"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Checkliste bearbeiten</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-text-field
                    v-model="editChecklistName"
                    label="Name der Checkliste"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-text-field
                    v-model="editChecklistSorter"
                    label="Sortierung"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog.editChecklist = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveChecklist()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="checklistCreationDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Neue Checkliste</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-text-field
                    v-model="newChecklistName"
                    label="Checklist Name"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <p>Checkliste wird hinzugefügt in Fahrzeug: <span v-if="vehicle">{{ vehicle.name }}</span></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="checklistCreationDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="createChecklist"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.confirmDelete"
        persistent
        max-width="290"
    >
      <v-card v-if="selectedList">
        <v-card-title class="headline">
          Checkliste {{ selectedList.title }} löschen?
        </v-card-title>
        <v-card-text>Diese Checkliste definiv löschen?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              text
              @click="dialog.confirmDelete = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="deleteChecklist()"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.confirmVehicleDelete"
        persistent
        max-width="290"
    >
      <v-card v-if="vehicle">
        <v-card-title class="headline">
          Fahrzeug {{ vehicle.name }} löschen?
        </v-card-title>
        <v-card-text>Das Fahrzeug wird entgültig entfernt mit allen angehängten Checklisten!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              text
              @click="dialog.confirmVehicleDelete = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="deleteVehicle()"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.confirmUserDelete"
        persistent
        max-width="290"
    >
      <v-card v-if="userDelete">
        <v-card-title class="headline">
          Benutzer {{ userDelete.username }} löschen?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              text
              @click="dialog.confirmUserDelete = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="deleteUser()"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.confirmFileDelete"
        persistent
        max-width="290"
    >
      <v-card v-if="fileDelete">
        <v-card-title class="headline">
          Datei {{ fileDelete.title }} löschen?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              text
              @click="dialog.confirmFileDelete = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="deleteFile()"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snack.state"
        :timeout="snack.timeout"
        :color="snack.color"
        elevation="12"
        top
        right
    >
      {{ snack.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: "Admin.vue",
  data() {
    return {
      vehicle: null,
      vehicles: null,
      userList: null,
      createVehicleDialog: false,
      newVehicle: null,
      newUser: null,
      newUserGroup: null,
      newUserPassword: null,
      editVehicleName: null,
      editChecklistName: null,
      editChecklistSorter: null,
      newModule: null,
      checklists: null,
      checklist: null,
      userDelete: null,
      editedUser: null,
      checklistCreationDialog: null,
      newChecklistName: null,
      selectedList: null,
      fileTitle: '',
      uploadedFile: null,
      fileList: [],
      addToVehicle: '',
      fileDelete: null,
      messageText: '',
      userGroups: ['benutzer', 'pruefer', 'admin'],
      dialog: {
        fileUpload: false,
        confirmDelete: false,
        confirmVehicleDelete: false,
        editVehicle: false,
        editChecklist: false,
        createUser: false,
        editUser: false,
        confirmUserDelete: false,
        confirmFileDelete: false
      },
      snack: {
        state: false,
        color: 'success',
        timeout: 2000,
        message: 'Checkliste wurde erfolgreich gespeichert!',
      }

    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      axios
          .get(process.env.VUE_APP_API_URL + '/vehicles')
          .then((response) => {
            this.vehicles = response.data.vehicles
          })

      axios.get('/auth/userlist')
          .then((response) => {
            this.userList = response.data
          })

      axios.get('/messages')
      .then((response) => {
        if(response.data){
          this.messageText = response.data.text
        }else{
          this.messageText = ''
        }
      })

      axios.get('/files')
          .then((response) => {
            if(response.data){
              this.fileList = response.data
            }
          })
    },
    uploadFile() {

      let formData = new FormData();
      console.log(this.uploadedFile)
      formData.append("attachment", this.uploadedFile);
      formData.append("title", this.fileTitle);


      axios.post(process.env.VUE_APP_API_URL + '/files', formData)
          .then((res) => {
            console.log(res)
          })
          .catch((error) => {
            console.log(error)
          }).finally(() => {
        this.dialog.fileUpload = false
        this.getData()
        this.fileTitle = null
        this.uploadedFile = null
      });
    },
    addVehicle() {
      axios.post(process.env.VUE_APP_API_URL + '/vehicles', {'name': this.newVehicle})
          .then((res) => {
            console.log(res)
          })
          .catch((error) => {
            console.log(error)
          }).finally(() => {
        this.createVehicleDialog = false
        this.getData()
        this.newVehicle = null
      });
    },
    vehicleIndex(id) {
      return this.vehicles.findIndex(i => i._id === id)
    },
    openChecklistDialog(vehicle) {
      this.vehicle = vehicle
      this.checklistCreationDialog = true
    },
    createChecklist() {
      axios.post(process.env.VUE_APP_API_URL + '/checklists/', {
        'title': this.newChecklistName,
        'vehicle': this.vehicle._id
      })
          .then((res) => {
            console.log(res)
          })
          .catch((error) => {
            console.log(error)
          }).finally(() => {
        this.checklistCreationDialog = false
        this.getData()
        this.newChecklistName = null
      })
    },
    createUser() {
      axios.post('/auth/register', {username: this.newUser, password: this.newUserPassword, group: this.newUserGroup})
          .then((res) => {
            console.log(res)
            if (res.status === 204) {
              this.dialog.createUser = false
              this.snack.message = "Benutzer wurde erstellt."
              this.snack.state = true

              this.newUser = null
              this.newUserGroup = null
              this.newUserPassword = null

              this.getData()
            }

          }).catch((error) => {
        console.log(error)
      })
    },
    editUser(user) {
      this.editedUser = user
      this.editedUser.password = ''
      this.dialog.editUser = true
    },
    saveCustomMessage(){
      axios.post('messages/', {'text': this.messageText})
      .then((res) =>{
        if (res.status === 204) {
          this.snack.message = "Text wurde gespeichert."
          this.snack.state = true
          this.getData()
        }
      })
    },
    saveUser() {
      axios.patch('/auth/user/' + this.editedUser._id, {
        'username': this.editedUser.username,
        'group': this.editedUser.group,
        'password': this.editedUser.password
      })
          .then((res) => {
            console.log(res)

            if (res.status === 204) {
              this.dialog.editUser = false
              this.snack.message = "Benutzer wurde angepasst."
              this.snack.state = true
              this.getData()
            }
          }).catch((error) => {
        console.log(error)
      })

    },
    confirmFileDelete(file) {
      this.fileDelete = file
      this.dialog.confirmFileDelete = true
    },
    confirmUserDelete(user) {
      this.userDelete = user
      this.dialog.confirmUserDelete = true
    },
    deleteUser() {
      axios.delete('/auth/user/' + this.userDelete._id)
          .then((res) => {
            console.log(res)

            if (res.status === 204) {
              this.dialog.confirmUserDelete = false
              this.snack.message = "Benutzer wurde entfernt."
              this.snack.state = true
              this.getData()
            }

          }).catch((error) => {
        console.log(error)
      })
    },
    deleteFile() {
      axios.delete('/files/' + this.fileDelete._id)
          .then((res) => {
            console.log(res)

            if (res.status === 200) {
              this.dialog.confirmFileDelete = false
              this.snack.message = "Datei wurde entfernt."
              this.snack.state = true
              this.getData()
            }

          }).catch((error) => {
        console.log(error)
      })
    },
    showChecklist(checklist) {
      this.$router.push({name: 'ChecklistCreator', params: {id: checklist._id}})
    },
    deleteChecklist() {
      axios.delete(process.env.VUE_APP_API_URL + '/checklists/' + this.selectedList._id)
          .then((res) => {
            console.log(res)

            if (res.status === 204) {
              this.dialog.confirmDelete = false
              this.snack.message = "Checkliste wurde entfernt."
              this.snack.state = true
              this.getData()
            }

          }).catch((error) => {
        console.log(error)
      }).finally(() => {

      })
    },
    confirmDelete(checklist) {
      this.selectedList = checklist
      this.dialog.confirmDelete = true
    },
    confirmVehicleDelete(vehicle) {
      this.vehicle = vehicle
      this.dialog.confirmVehicleDelete = true
    },
    deleteVehicle() {
      axios.delete(process.env.VUE_APP_API_URL + '/vehicles/' + this.vehicle._id)
          .then((res) => {
            console.log(res)

            if (res.status === 204) {
              this.dialog.confirmVehicleDelete = false
              this.snack.message = "Fahrzeug wurde entfernt."
              this.snack.state = true
              this.getData()
            }

          }).catch((error) => {
        console.log(error)
      }).finally(() => {

      })
    },
    editChecklist(list) {
      this.checklist = list
      this.dialog.editChecklist = true
      this.editChecklistName = list.title
      this.editChecklistSorter = list.sorter
    },
    saveChecklist() {
      axios.patch(process.env.VUE_APP_API_URL + '/checklists/' + this.checklist._id, {
        title: this.editChecklistName,
        sorter: this.editChecklistSorter
      })
          .then((res) => {
            console.log(res)

            if (res.status === 204) {
              this.dialog.editChecklist = false
              this.snack.message = "Checkliste wurde gespeichert."
              this.snack.state = true
              this.getData()
            }

          }).catch((error) => {
        console.log(error)
      }).finally(() => {

      })
    },
    editVehicle(vehicle) {
      this.vehicle = vehicle
      this.dialog.editVehicle = true
      this.editVehicleName = vehicle.name
    },
    saveVehicle() {
      axios.patch(process.env.VUE_APP_API_URL + '/vehicles/' + this.vehicle._id, {name: this.editVehicleName})
          .then((res) => {
            console.log(res)

            if (res.status === 204) {
              this.dialog.editVehicle = false
              this.snack.message = "Fahrzeug wurde gespeichert."
              this.snack.state = true
              this.getData()
            }

          }).catch((error) => {
        console.log(error)
      }).finally(() => {

      })
    }

  }
}
</script>

<style scoped>

</style>